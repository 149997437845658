.color-212529 {
  color: #212529;
}

.color-e23237 {
  color: #e23237;
}

.bg-e23237 {
  background-color: #e23237;
}

.opacity-hover-07:hover {
  opacity: 0.7;
}