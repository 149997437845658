.cursor-pointer {
  cursor: pointer;
}

.transition-03-all {
  transition: 0.3s all;
}

::-webkit-scrollbar {
  width: 3px;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #cccccc;
}

.opacity-hover-07:hover {
  opacity: 0.7;
}

.black-color-hover:hover {
  color: #000000 !important;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.z-index-plus-999 {
  z-index: 999;
}

.background-size-cover {
  background-size: cover;
}


.background-position-top {
  background-position: top;
}

.background-position-center {
  background-position: center;
}

.btn-link {
  &:hover {
    .material-icons {
      transform: translateX(5px);
    }
  }
}
