nav {
  .navbar-toggler {
    &:focus {
      box-shadow: none;
    }
  }

  .navbar-brand {
    padding: 5px 0;
  }

  .logo {
    width: 210px;

    @media(max-width: 991px) {
      width: 180px;
    }
  }


  .navbar-nav {
    transform: translateY(2px);
  }

  .nav-wrapper {
    height: 70px;

    @media(max-width: 991px) {
      height: unset;
      transition: unset;
    }
  }

  &.scrolled {
    .logo {
      width: 180px;
    }

    .nav-wrapper {
      height: 60px;

      @media(max-width: 991px) {
        height: unset;
        transition: unset;
      }
    }
  }


  .languages {
    li {

      &.current-lang a {
        color: black;
      }

      a {
        color: rgba(black,.5);
        text-transform: uppercase;
        text-decoration: none;
        margin-right: 10px;
        padding-right: 10px;
        border-right: 1px solid #ccc;
        font-size: 12px;
        transition: 0.3s all;
        font-weight: 500;

        &:hover {
          color: #191919;
        }
      }

      &:last-child {
        a {
          padding-right: 0;
          margin-right: 0;
          border-right: 0;
        }
      }
    }
  }



}