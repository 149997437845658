.nfj-cookie-banner {
  bottom: 0;
  left: 0;
  z-index: 999;
  display: none;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .5);

  p {
    line-height: initial;
    margin: 0;
  }

  &.active {
    display: block;
  }
}
