@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-26 {
  font-size: 26px;
}


.font-size-34 {
  font-size: 34px;
}

.font-size-40 {
  font-size: 40px;
}


.font-weight-100 {
  font-weight: 100;
}

.font-weight-200 {
  font-weight: 200;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-900 {
  font-weight: 900;
}

.list-style-none {
  list-style: none;
}

.text-underline {
  text-decoration: underline;
}

.text-justify {
  text-align: justify;
}

.wysiwig-container {
  p, li {
    font-size: 12px;
    color: #000000;
  }

  a {
    color: #757575;
    text-decoration: underline;

    &:hover {
      opacity: 0.7;
    }
  }

  &.alternate {
    p, li {
      line-height: 2;
      font-size: 14px;
    }
  }
}