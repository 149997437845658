@import '../../node_modules/aos/dist/aos.css';
@import '../../assets/style/global.scss';
@import '../../components/navigation/navigation.scss';
@import '../../components/footer/footer.scss';
@import '../../node_modules/tiny-slider/dist/tiny-slider.css';
@import '../../components/cookie/cookie.scss';

main {
  .slider-section {
    //margin-top: 112px;
    min-height: 94.5vh;

    padding: 75px 0 20px 0;

    @media(max-width: 991px) {
      padding: 50px 0 20px 0;
    }

    &:hover .overlay {
      background-color: unset;
      filter: grayscale(0);
    }

    .overlay {
      filter: grayscale(100%);
      background-color: rgba(#000000, 0.5);

      &:hover {
        filter: grayscale(0);
        background-color: unset;
      }
    }

    .slider-btn {
      &:hover {
        .material-icons {
          transform: translateX(5px);
        }
      }
    }

    article {
      min-height: 700px;

      .bg-image {
        filter: grayscale(100%);
        z-index: -3;
        transition: 0.3s all;
      }

      &:hover {
        .bg-image {
          filter: grayscale(0);

        }
      }

      .bg-opacity {
        background: rgba(black, 0.8);
        z-index: -2;
      }

      .slider-inner {
        z-index: 99;
      }
    }

    .tns-nav {
      display: flex !important;
      justify-content: center;
      align-items: center;
      //min-height: 10vh;
      min-height: 70px;
      //padding: 40px 0;

      button {
        margin: 0 10px;
        width: 50px;
        height: 3px;
        border: 0;
        background-color: #cccccc;

        &.tns-nav-active {
          background-color: #191919;
        }
      }
    }

  }
}
